import { NavLink, useLocation } from "react-router-dom";
import DashboardIcon from "./icons/DashboardIcon";
import ModuleIcon from "./icons/ModuleIcon";
import RightArrowIcon from "./icons/RightArrowIcon";
import UseCaseIcon from "./icons/UseCaseIcon";
import { useState } from "react";
import AnimatedBorder from "./AnimatedBorder";

const MainNavigationDesktop = () => {
  const location = useLocation();
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [isUseCaseOpen, setIsUseCaseOpen] = useState(false);

  const getActiveClass = (path) => {
    return location.pathname === path
      ? "bg-gradient-to-r from-[#00D05E]/20 to-purple-500/20 text-white border border-[#00D05E]/30 pl-2"
      : "text-gray-400 hover:text-white border border-transparent";
  };

  return (
    <div className="w-64 select-none hidden md:block">
      <div className="fixed w-64 h-[96%]">
        <div className="relative h-full">
          <AnimatedBorder extraClasses="absolute w-full top-[50%] transform -translate-y-[50%]">
            <div className="bg-black/95 backdrop-blur-md rounded-3xl border border-[#00D05E]/30 p-6 shadow-xl h-full">
              <div className="mb-[35px] text-center">
                <p className="text-gray-400 font-semibold text-[12px]">
                  Welcome to
                </p>
                <h1 className="text-[32px] font-bold text-white">Optium</h1>
                <p className="text-gray-400 font-semibold text-[12px]">
                  Your Claims Assistant
                </p>
              </div>

              <nav className="space-y-5 font-medium">
                <NavLink
                  to="/dashboard"
                  className={`flex items-center justify-start rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                    "/dashboard"
                  )}`}
                >
                  <DashboardIcon color="currentColor" />
                  <span className="ml-[14px]">Dashboard</span>
                </NavLink>

                <div>
                  <div
                    className="flex items-center text-gray-400 cursor-pointer hover:text-white"
                    onClick={() => setIsModuleOpen(!isModuleOpen)}
                  >
                    <div className="w-6 h-6 grid place-items-center">
                      <ModuleIcon color="currentColor" />
                    </div>
                    <span className="ml-[14px]">Modules</span>
                    <span className="ml-auto">
                      <div
                        className={`transform transition-transform duration-300 ${
                          isModuleOpen ? "rotate-90" : ""
                        }`}
                      >
                        <RightArrowIcon color="currentColor" />
                      </div>
                    </span>
                  </div>
                  <div
                    className={`overflow-hidden transition-all duration-300 ease-in-out ${
                      isModuleOpen
                        ? "max-h-64 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="pl-6 mt-3 space-y-[7px] border-l border-gray-400 ml-3">
                      <NavLink
                        to="/dashboard/guided-text"
                        className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                          "/dashboard/guided-text"
                        )}`}
                      >
                        <span>Guided Free Text Input</span>
                        <span className="ml-auto">
                          <RightArrowIcon color="currentColor" />
                        </span>
                      </NavLink>

                      <NavLink
                        to="/dashboard/category-selection"
                        className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                          "/dashboard/category-selection"
                        )}`}
                      >
                        <span>Category Selection Demo</span>
                        <span className="ml-auto">
                          <RightArrowIcon color="currentColor" />
                        </span>
                      </NavLink>

                      <NavLink
                        to="/dashboard/inconsistency"
                        className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                          "/dashboard/inconsistency"
                        )}`}
                      >
                        <span>Inconsistency Identification</span>
                        <span className="ml-auto">
                          <RightArrowIcon color="currentColor" />
                        </span>
                      </NavLink>

                      <NavLink
                        to="/dashboard/policy-extraction"
                        className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                          "/dashboard/policy-extraction"
                        )}`}
                      >
                        <span>Policy Extraction</span>
                        <span className="ml-auto">
                          <RightArrowIcon color="currentColor" />
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    className="flex items-center text-gray-400 cursor-pointer hover:text-white"
                    onClick={() => setIsUseCaseOpen(!isUseCaseOpen)}
                  >
                    <div className="w-6 h-6 grid place-items-center">
                      <UseCaseIcon color="currentColor" />
                    </div>
                    <span className="ml-[14px]">Use-Cases</span>
                    <span className="ml-auto">
                      <div
                        className={`transform transition-transform duration-300 ${
                          isUseCaseOpen ? "rotate-90" : ""
                        }`}
                      >
                        <RightArrowIcon color="currentColor" />
                      </div>
                    </span>
                  </div>

                  <div
                    className={`overflow-hidden transition-all duration-300 ease-in-out ${
                      isUseCaseOpen
                        ? "max-h-64 opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                  >
                    <div className="pl-6 mt-3 space-y-[7px] border-l border-gray-400 ml-3">
                      <NavLink
                        to="/dashboard/cause-sub-cause-selection"
                        className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                          "/dashboard/cause-sub-cause-selection"
                        )}`}
                      >
                        <span>Cause Sub-cause Selection</span>
                        <span className="ml-auto">
                          <RightArrowIcon color="currentColor" />
                        </span>
                      </NavLink>

                      <NavLink
                        to="/dashboard/policy-validation"
                        className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                          "/dashboard/policy-validation"
                        )}`}
                      >
                        <span>Policy Validation</span>
                        <span className="ml-auto">
                          <RightArrowIcon color="currentColor" />
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </AnimatedBorder>
        </div>
      </div>
    </div>
  );
};

export default MainNavigationDesktop;
