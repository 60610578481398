// src/components/Layout.js
import { useEffect } from 'react';

const Layout = ({ children }) => {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.defer = true;
    script1.setAttribute('data-domain', 'yeo.rightindem.com');
    script1.src = 'https://plausible.io/js/script.file-downloads.hash.outbound-links.pageview-props.tagged-events.js';

    const script2 = document.createElement('script');
    script2.text = `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`;

    document.head.appendChild(script1);
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return children;
};

export default Layout;