import { CircleArrowDown } from "lucide-react";

const TryDemoButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-[#00D05E] text-white p-2 rounded-full hover:bg-[#00D05E]/75 transition-colors"
    >
      <CircleArrowDown size={36} />
    </button>
  );
};

export default TryDemoButton;
