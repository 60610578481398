/* eslint-disable react-hooks/exhaustive-deps */
// src/components/LoadingSequence.jsx
import React, { useState, useEffect } from "react";

const LoadingSequence = () => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const messages = [
    "Analysing description",
    "Looking at categories",
    "Making a decision",
    "Here's your result",
  ];

  useEffect(() => {
    if (currentMessage < messages.length) {
      const timer = setTimeout(() => {
        setCurrentMessage((prev) => prev + 1);
      }, 3500);
      return () => clearTimeout(timer);
    } else {
      setCurrentMessage(0);
    }
  }, [currentMessage]);

  return (
    // Changed position to fixed and increased z-index significantly
    <div className="fixed inset-0 z-[9999] flex items-center justify-center overflow-hidden rounded-3xl">
      {/* Darkened background with stronger blur */}
      <div className="absolute inset-0 bg-black/80 backdrop-blur-[30px] overflow-hidden rounded-3xl" />

      {/* Animated blobs with increased size and opacity */}
      <div className="absolute inset-0 overflow-hidden rounded-3xl">
        <div className="absolute w-[800px] h-[800px] -top-1/4 -left-1/4 animate-blob1">
          <div className="w-full h-full bg-[#00D05E]/40 rounded-full blur-[150px]" />
        </div>
        <div className="absolute w-[800px] h-[800px] -bottom-1/4 -right-1/4 animate-blob2">
          <div className="w-full h-full bg-purple-500/40 rounded-full blur-[150px]" />
        </div>
        <div className="absolute w-[800px] h-[800px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-blob3">
          <div className="w-full h-full bg-pink-500/40 rounded-full blur-[150px]" />
        </div>
      </div>

      {/* Centered text with increased z-index */}
      <div className="relative z-10 w-full h-full">
        {messages.map((message, index) => (
          <div
            key={message}
            className={`w-full h-full absolute text-3xl md:text-6xl font-bold text-white/90 text-center flex justify-center items-center
              ${
                index === currentMessage
                  ? "animate-fade-in opacity-100"
                  : index < currentMessage
                  ? "animate-fade-out opacity-0"
                  : "opacity-0"
              }`}
            style={{
              textShadow: "0 4px 30px rgba(255,255,255,0.4)",
            }}
          >
            {message}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadingSequence;
