const DashboardIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="6"
        height="7"
        x="4.5"
        y="4"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.154"
        rx="1.154"
      ></rect>
      <rect
        width="6"
        height="5"
        x="4.5"
        y="15"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.154"
        rx="1.154"
      ></rect>
      <rect
        width="6"
        height="5"
        x="14.494"
        y="4"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.154"
        rx="1.154"
      ></rect>
      <rect
        width="6"
        height="7"
        x="14.494"
        y="12.999"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.154"
        rx="1.154"
      ></rect>
    </svg>
  );
};

export default DashboardIcon;
