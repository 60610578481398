/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import AnimatedBackground from "./components/AnimatedBackground";
import Player from '@vimeo/player';
import TypeWriter from "./components/TypeWriter";
import { GUIDED_FREE_TEXT_URL, GUIDED_FREE_TEXT_PROMPTID, HEADER_CLIENT_KEY } from "./utils/apiManager";
import { GUIDELINES } from "./utils/constants";
import useAccessToken from './useAccessToken';
import { PlayCircle } from 'lucide-react';
import {
  ChevronRight,
  CircleHelp,
  MessageCircle,
  RefreshCw,
} from "lucide-react";
import {
  CheckCircle2,
  Clock,
  Zap,
  Shield,
  MousePointerClick,
} from "lucide-react";
import { AlertCircle, Lightbulb, MessageSquare } from "lucide-react";
import AnimatedBorder from "./components/AnimatedBorder";
import TryDemoButton from "./components/TryDemoButton";
import TwoStepProgress from "./components/TwoStepProgress";

const GuidedFreeText = () => {
  const demoRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");
  const [aiGuidance, setAiGuidance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const resetState = () => {
    setCurrentStep(1);
    setDescription("");
    setAiGuidance("");
    setIsLoading(false);
    setTypingTimeout(null);
  };
  const accessToken = useAccessToken();
  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const videoContainerRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const playerInstanceRef = useRef(null);

  useEffect(() => {
    if (videoContainerRef.current) {
      const options = {
        url: 'https://vimeo.com/1029828333/8c9f5e63cd',
        autoplay: false, // Changed to false
        loop: true,
        controls: true,
        responsive: true,
        muted: false, // Changed to false
        title: false,
        byline: false,
        portrait: false
      };
  
      const player = new Player(videoContainerRef.current, options);
      playerInstanceRef.current = player;
  
      player.ready().then(() => {
        player.setVolume(1);
      }).catch(error => {
        console.error("Player ready error:", error);
      });
  
      return () => {
        if (playerInstanceRef.current) {
          playerInstanceRef.current.destroy();
        }
      };
    }
  }, []);
  
  // Add this handler function
  const handlePlayVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play().then(() => {
        setIsVideoPlaying(true);
      }).catch(error => {
        console.error("Error playing video:", error);
      });
    }
  };

  // Debounced API call when user stops typing
  useEffect(() => {
    if (description) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      const timeout = setTimeout(() => {
        fetchGuidance(description);
      }, 600);

      setTypingTimeout(timeout);
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [description]);


  const fetchGuidance = async (text) => {
    setIsLoading(true);
    try {
      // const response = await fetch(GUIDED_FREE_TEXT_URL, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
      //   },
      //   body: JSON.stringify({
      //     claim_data: {
      //       claim_narrative: text,
      //     },
      //   }),
      // });
      // const data = await response.json();

      const requestAiServiceBody = {
        promptId: GUIDED_FREE_TEXT_PROMPTID,
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: `{'guideline': '${GUIDELINES.GUIDED_FREE_TEXT.guideline_motor}', 'freetext': '${text}'}`
          }
        ]
      };
      const apiPromise = fetch(GUIDED_FREE_TEXT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          // -> client key for AI Service
          "x-client-key": HEADER_CLIENT_KEY,
          // bearer token from auth0
          "Authorization": `Bearer ${accessToken}`
        },
 
        body: JSON.stringify(requestAiServiceBody),
      }
      ).then((res) => res.json());

      const [data] = await Promise.all([
        apiPromise,
      ]);
      let module_response = data.result;
 
      module_response = module_response.replace("```json\n", "").replace("\n```", "");
 
      module_response = JSON.parse(module_response);
      console.log(module_response);

      setAiGuidance(module_response.Explanation || "");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Render function for different steps
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div className="row-start-2 md:row-start-1">
                <p className="text-white mb-3 flex justify-start items-center">
                  <CircleHelp size={24} color="#00D05E" />
                  <span className="ml-2">Describe your Incident</span>
                </p>
                <div className="w-full h-64 bg-black/20 rounded-3xl">
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Write your incident description here..."
                    className="w-full h-full text-white focus:outline-none resize-none placeholder-gray-500 bg-black/20 p-4 rounded-3xl"
                  />
                </div>
              </div>
              <div className="row-start-1 md:row-start-1">
                <p className="text-white mb-3 flex justify-start items-center">
                  <MessageCircle size={24} color="#00D05E" />
                  <span className="ml-2">Optium</span>
                </p>
                <div className="relative w-full h-64 bg-black/20 rounded-3xl p-4 overflow-y-auto custom-scrollbar">
                  <div className="absolute top-6 right-6 px-2 py-1 text-sm text-[#00D05E] flex items-center z-50">
                    <div
                      className={`w-2 h-2 rounded-full mr-2 ${
                        isLoading
                          ? "bg-yellow-500 animate-pulse"
                          : aiGuidance
                          ? "bg-[#00D05E]"
                          : "bg-[#00D05E]"
                      }`}
                    />
                    {isLoading
                      ? "Thinking..."
                      : aiGuidance
                      ? "Active"
                      : "Waiting for input"}
                  </div>
                  {aiGuidance ? (
                    <div className="flex items-start gap-3 animate-fade-in">
                      <div className="w-8 h-8 rounded-full bg-[#00D05E]/20 flex items-center justify-center border border-[#00D05E]/30">
                        <MessageCircle size={16} className="text-[#00D05E]" />
                      </div>
                      <div className="flex-1">
                        <div className="bg-[#00D05E]/30 text-white p-4 pt-9 rounded-3xl backdrop-blur-sm border border-[#00D05E]/30">
                          {aiGuidance}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="h-full flex items-center justify-center text-gray-400">
                      <p className="text-center">
                        Start typing your incident description, and I'll help
                        guide you through the process.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <button
                onClick={() => setCurrentStep(2)}
                disabled={!description.trim()}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50"
              >
                Continue
              </button>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4 text-white">
            {/* Features Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 opacity-0 animate-fade-in">
              {/* Card 1 */}
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <MousePointerClick className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Universal Application" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Applicable to any free text field in your application"
                      delay={10}
                    />
                  </p>
                </div>
              </AnimatedBorder>

              {/* Card 2 */}
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <Zap className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Fast Execution" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Average execution is < 1 second for fields with 200-word limit"
                      delay={15}
                    />
                  </p>
                </div>
              </AnimatedBorder>

              {/* Card 3 */}
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <Clock className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Flexible Timing" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Run on submit or in real-time as the user types"
                      delay={20}
                    />
                  </p>
                </div>
              </AnimatedBorder>

              {/* Card 4 */}
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <Shield className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="PII Compatible" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Safe to use on fields where PII data is requested"
                      delay={25}
                    />
                  </p>
                </div>
              </AnimatedBorder>
            </div>

            {/* Add particle effect background */}
            <div className="absolute inset-0 pointer-events-none">
              <div className="particles-container">
                {/* Add 20 particle elements */}
                {[...Array(20)].map((_, i) => (
                  <div
                    key={i}
                    className="particle"
                    style={{
                      "--particle-speed": `${Math.random() * 10 + 5}s`,
                      "--particle-x": `${Math.random() * 100}%`,
                      "--particle-delay": `${Math.random() * 5}s`,
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={resetState}
                disabled={!description.trim() || isLoading}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50 flex justify-center items-center"
              >
                <RefreshCw size={18} />
                <span className="ml-2">Try Again</span>
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative py-32">
      <AnimatedBackground />
      {/* First Section */}
{/* Hero Section with Video and Info Boxes */}
<div className="max-w-5xl mx-auto mb-9">
  {/* Title and Video Row */}
  <div className="flex flex-col md:flex-row items-start justify-between gap-8 mb-6">
    <div className="flex-1">
      <h1 className="font-['Impact'] text-[32px] md:text-[40px] text-[#00D05E] mb-4">
        Welcome to Guided Free Text Module
      </h1>
      <p className="text-[#ACACAC] leading-relaxed mb-6">
        Meet your AI assistant that helps guide you through providing detailed incident descriptions.
      </p>
      
      {/* Info Boxes Below Title */}
      <div className="grid grid-cols-1 gap-6">
        <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
          <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
            <MessageCircle />
            <span className="ml-2">AI-Powered Text Analysis</span>
          </h2>
          <p className="leading-[160%] font-light text-[#ACACAC]">
            Optium's Guided Free Text Module helps claimants
            provide all the necessary information in their incident
            description. Optium analyses the claimant's description
            in real-time, prompting them for missing information that's
            required to progress the claim.
          </p>
        </section>
        
        <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
          <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
            <AlertCircle />
            <span className="ml-2">Current Challenges</span>
          </h2>
          <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
            <ChevronRight size={14} />
            <span className="ml-2">Manual intervention required</span>
          </p>
          <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
            <ChevronRight size={14} />
            <span className="ml-2">Settlement delays</span>
          </p>
          <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
            <ChevronRight size={14} />
            <span className="ml-2">Increased processing costs</span>
          </p>
          {/* <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
            <ChevronRight size={14} />
            <span className="ml-2">80% claims with missing data</span>
          </p> */}
        </section>
      </div>
    </div>
    
    {/* Video Avatar */}
    <div className="w-full md:w-[320px] shrink-0">
      <div className="aspect-[9/16] relative rounded-xl border border-gray-800 overflow-hidden bg-black/40">
        <div 
          ref={videoContainerRef} 
          className="w-full h-full z-10 relative pointer-events-auto"
          style={{
            position: 'relative',
            zIndex: 50
          }}
        />
        
        {/* Play Overlay */}
        {!isVideoPlaying && (
          <div 
            onClick={handlePlayVideo}
            className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 cursor-pointer z-[60] transition-opacity hover:bg-black/40"
          >
            <div className="w-16 h-16 rounded-full bg-[#00D05E]/20 flex items-center justify-center backdrop-blur-sm border border-[#00D05E]/30 mb-3">
              <PlayCircle size={40} className="text-[#00D05E]" />
            </div>
            <p className="text-white text-center px-4">
              Click to meet your AI assistant
            </p>
          </div>
        )}
      </div>
    </div>
  </div>
</div>

{/* Technical Solution Section */}
<div className="max-w-5xl mx-auto">
  <div className="grid grid-cols-1 gap-6 mb-9">
    <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
            <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
              <Lightbulb />
              <span className="ml-2">Technical Solution</span>
            </h2>
            <p className="leading-[160%] font-light text-[#ACACAC] mb-3">
              The Guided Free Text module provides real-time analysis and
              guidance through:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <Zap />
                  <span className="ml-2">Real-time Analysis</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Continuous monitoring of input
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <MessageSquare />
                  <span className="ml-2">Smart Prompts</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Context-aware suggestions
                </p>
              </div>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
                <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
                  <CheckCircle2 />
                  <span className="ml-2">Validation</span>
                </p>
                <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
                  Non-intrusive guidance
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="hidden md:flex justify-center items-center mb-9">
          <TryDemoButton onClick={scrollToDemo} />
        </div>
      <section ref={demoRef}>
        {/* Third Section */}
        <div className="max-w-4xl mx-auto text-center mb-9 pt-9">
          <h2 className="text-2xl font-bold text-[#00D05E] mb-2">
            GUIDED FREE TEXT DEMO
          </h2>
          <p className="leading-[160%] font-light text-[#ACACAC]">
            Experience real-time AI guidance as you describe your incident. {" "}
            <span className="text-[#F93DCD]">Optium</span> will analyse
            your description and suggest additional details that could be
            helpful to include.
          </p>
        </div>
        {/* Forth Section */}
        <div className="max-w-4xl mx-auto text-center mb-9">
          <TwoStepProgress currentStep={currentStep} />
        </div>
        {/* Fifth Section */}
        <div className="max-w-5xl mx-auto">
          <AnimatedBorder>
            <div className="bg-black backdrop-blur-sm rounded-3xl border border-gray-800 p-6 overflow-hidden">
              {/* Content Card */}
              <AnimatedBackground />
              {renderStep()}
            </div>
          </AnimatedBorder>
        </div>
      </section>
    </div>
  );
};

export default GuidedFreeText;
