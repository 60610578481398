import React from "react";
import { Dog, Factory, House, ExternalLink } from "lucide-react";

const INCIDENT_TYPES = [
  {
    id: "motor",
    label: "Motor",
    value: "Motor",
    icon: Factory,
    color: "#00D05E",
    size: 24,
    description: "We have 121 categories",
    listUrl: "#"
  },
  {
    id: "home",
    label: "Home",
    value: "Home",
    icon: House,
    color: "#00D05E",
    size: 24,
    description: "We have 71 categories",
    listUrl: "#"
  },
  {
    id: "pet",
    label: "Pet",
    value: "Pet",
    icon: Dog,
    color: "#00D05E",
    size: 24,
    description: "We have 78 categories",
    listUrl: "#"
  },
];

const LineOfBusinessSelector = ({ selected, onSelect }) => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    {INCIDENT_TYPES.map((type) => {
      const Icon = type.icon;
      return (
        <div
          key={type.id}
          onClick={() => onSelect(type)}
          className={`relative p-4 rounded-lg cursor-pointer transition-all duration-300 ${
            selected?.id === type.id
              ? "bg-gradient-to-br from-gray-800/50 to-gray-900/50 border-2 border-[#00D05E] scale-105"
              : "bg-black/20 border border-gray-800 hover:border-[#00D05E]/50"
          }`}
        >
          <div className="flex flex-col items-center text-center gap-3">
            <div
              className={`w-12 h-12 rounded-full bg-gradient-to-br from-gray-800 to-black flex items-center justify-center ${
                selected?.id === type.id ? "animate-pulse" : ""
              }`}
            >
              <Icon size={type.size} color={type.color} />
            </div>
            <div>
              <h3 className="text-white font-semibold text-lg mb-2">{type.label}</h3>
              <a 
                href={type.listUrl}
                onClick={(e) => e.stopPropagation()} 
                className="group text-gray-400 text-base hover:text-[#00D05E] transition-colors inline-flex items-center gap-1"
              >
                {type.description}
                <ExternalLink 
                  size={16} 
                  className="inline ml-1 opacity-0 group-hover:opacity-100 transition-opacity" 
                />
              </a>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default LineOfBusinessSelector;