export const GUIDELINES = {
    GUIDED_FREE_TEXT: {
      guideline_motor: "Describe what happened, noting how, Approximate Location (for example, street name, etc.). Comment on the area of impact on the vehicle and specific damage caused. Include information about any other vehicles or property involved. ONLY IF there is any other vehicle, comment on the direction of their travel in relation to your vehicle (i.e. coming from behind/opposite direction and making a left/right turn, parking/parked, moving forward/reversing).",
      guideline_home: "Describe what happened, specifying the area of the home affected (e.g., kitchen, backyard). Mention any damage to the property. Mention any actions taken to address the situation (For example, calling for repairs, contacting emergency services, and include any relevant causes, like weather conditions, etc.).",
      guideline_pet: "Describe what happened, including the pet's actions and the approximate location (e.g., backyard, park). Note any injuries or distress to the pet and visible signs of harm. ONLY if other animals or people were involved, describe what they did. Mention any actions taken (For example, visiting a vet, contacting authorities, etc.)"
    }
  };

  export const POLICYHOLDER_SCHEDULE = {
    MOTOR: {
      schedule: `Coverage Details: \n Liability Limits: Bodily Injury £100,000/£300,000, Property Damage £50,000 \n Collision Deductible: £500 \n Comprehensive Deductible: £300 \n Additional Coverages: \n - Motor Legal Assistance \n \n Premium Summary: \n Total Annual Premium: £850 \n Monthly Installment: £70.83`
    }
  };