import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useAccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          // const token = await getAccessTokenSilently({cacheMode: "off"});
          const token = await getAccessTokenSilently();
          setAccessToken(token);
        } catch (error) {
          console.error('Error getting access token', error);
        }
      }
    };

    getToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  return accessToken;
};

export default useAccessToken;