import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "./icons/DashboardIcon";
import ModuleIcon from "./icons/ModuleIcon";
import RightArrowIcon from "./icons/RightArrowIcon";
import UseCaseIcon from "./icons/UseCaseIcon";
import { useState } from "react";
import { CircleX } from "lucide-react";
import AnimatedBorder from "./AnimatedBorder";

const MainNavigationMobile = ({ isMobileNavOpen, toggleMobileNav }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModuleOpen, setIsModuleOpen] = useState(true);
  const [isUseCaseOpen, setIsUseCaseOpen] = useState(false);

  const getActiveClass = (path) => {
    return location.pathname === path
      ? "bg-gradient-to-r from-[#00D05E]/20 to-purple-500/20 text-white border border-[#00D05E]/30 pl-2"
      : "text-gray-400 hover:text-white border border-transparent";
  };

  let navContainerClasses =
    "fixed w-full h-full select-none md:hidden -my-[14px] -mx-4 bg-black/75 transform -translate-x-[100%] transition-transform duration-300";
  if (isMobileNavOpen) {
    navContainerClasses =
      "fixed w-full h-full select-none md:hidden -my-[14px] -mx-4 bg-black/75 transform translate-x-0 transition-transform duration-300";
  }

  const navigateToARoute = (route) => {
    toggleMobileNav();
    navigate(route);
  };

  return (
    <div className={navContainerClasses} style={{ zIndex: 999 }}>
      <div className="absolute top-4 right-4" style={{ zIndex: 1000 }}>
        <button className="text-white" onClick={toggleMobileNav}>
          <CircleX size={32} />
        </button>
      </div>
      <div className="relative h-full">
        <AnimatedBorder extraClasses="absolute w-full h-full">
          <div className="bg-black/95 backdrop-blur-md rounded-3xl border border-[#00D05E]/30 p-6 shadow-xl h-full">
            <div className="mb-[35px]">
              <span className="block text-center bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text text-[8.19px] font-semibold leading-none">
                Welcome to
              </span>
              <span className="block text-center bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text text-[21.84px] font-bold leading-tight">
                Optium
              </span>
              <span className="block text-center bg-gradient-to-r from-white to-[#999999] text-transparent bg-clip-text text-[8.19px] font-semibold leading-none">
                Your Claims Assistant
              </span>
            </div>

            <nav className="space-y-5 font-medium">
              <button
                onClick={() => navigateToARoute("/dashboard")}
                className={`flex items-center justify-start rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                  "/dashboard"
                )}`}
              >
                <DashboardIcon color="currentColor" />
                <span className="mx-[14px]">Dashboard</span>
              </button>

              <div>
                <div
                  className="flex items-center text-gray-400 cursor-pointer hover:text-white"
                  onClick={() => setIsModuleOpen(!isModuleOpen)}
                >
                  <div className="w-6 h-6 grid place-items-center">
                    <ModuleIcon color="currentColor" />
                  </div>
                  <span className="ml-[14px]">Modules</span>
                  <span className="ml-auto">
                    <div
                      className={`transform transition-transform duration-300 ${
                        isModuleOpen ? "rotate-90" : ""
                      }`}
                    >
                      <RightArrowIcon color="currentColor" />
                    </div>
                  </span>
                </div>

                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    isModuleOpen ? "max-h-64 opacity-100" : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="pl-6 mt-3 space-y-[7px] border-l border-gray-400 ml-3">
                    <button
                      onClick={() => navigateToARoute("/dashboard/guided-text")}
                      className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                        "/dashboard/guided-text"
                      )}`}
                    >
                      <span>Guided Free Text Input</span>
                      <span className="ml-auto">
                        <RightArrowIcon color="currentColor" />
                      </span>
                    </button>

                    <button
                      onClick={() =>
                        navigateToARoute("/dashboard/category-selection")
                      }
                      className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                        "/dashboard/category-selection"
                      )}`}
                    >
                      <span>Category Selection Demo</span>
                      <span className="ml-auto">
                        <RightArrowIcon color="currentColor" />
                      </span>
                    </button>

                    <button
                      onClick={() =>
                        navigateToARoute("/dashboard/inconsistency")
                      }
                      className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                        "/dashboard/inconsistency"
                      )}`}
                    >
                      <span>Inconsistency Identification</span>
                      <span className="ml-auto">
                        <RightArrowIcon color="currentColor" />
                      </span>
                    </button>

                    <button
                      onClick={() =>
                        navigateToARoute("/dashboard/policy-extraction")
                      }
                      className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                        "/dashboard/policy-extraction"
                      )}`}
                    >
                      <span>Policy Extraction</span>
                      <span className="ml-auto">
                        <RightArrowIcon color="currentColor" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className="flex items-center text-gray-400 cursor-pointer hover:text-white"
                  onClick={() => setIsUseCaseOpen(!isUseCaseOpen)}
                >
                  <div className="w-6 h-6 grid place-items-center">
                    <UseCaseIcon color="currentColor" />
                  </div>
                  <span className="ml-[14px]">Use-Cases</span>
                  <span className="ml-auto">
                    <div
                      className={`transform transition-transform duration-300 ${
                        isUseCaseOpen ? "rotate-90" : ""
                      }`}
                    >
                      <RightArrowIcon color="currentColor" />
                    </div>
                  </span>
                </div>

                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    isUseCaseOpen ? "max-h-64 opacity-100" : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="pl-6 mt-3 space-y-[7px] border-l border-gray-400 ml-3">
                    <button
                      onClick={() =>
                        navigateToARoute("/dashboard/cause-sub-cause-selection")
                      }
                      className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                        "/dashboard/cause-sub-cause-selection"
                      )}`}
                    >
                      <span>Cause Sub-cause Selection</span>
                      <span className="ml-auto">
                        <RightArrowIcon color="currentColor" />
                      </span>
                    </button>

                    <button
                      onClick={() =>
                        navigateToARoute("/dashboard/policy-validation")
                      }
                      className={`flex items-center justify-between rounded-lg transition-all duration-300 py-[5px] ${getActiveClass(
                        "/dashboard/policy-validation"
                      )}`}
                    >
                      <span>Policy Validation</span>
                      <span className="ml-auto">
                        <RightArrowIcon color="currentColor" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </AnimatedBorder>
      </div>
    </div>
  );
};

export default MainNavigationMobile;
