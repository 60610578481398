// src/components/WeatherParticles.js
import React from 'react';

const WeatherParticles = ({ type }) => {
  const createParticles = (count) => {
    return [...Array(count)].map((_, i) => (
      <div
        key={i}
        className={`particle-${type}`}
        style={{
          '--x': `${Math.random() * 100}%`,
          '--delay': `${Math.random() * 2}s`,
          '--duration': `${(Math.random() * 0.7 + 0.3)}s`
        }}
      />
    ));
  };

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {createParticles(type === 'rain' ? 500 : type === 'snow' ? 200 : 20)}
    </div>
  );
};

export default WeatherParticles;