import React, { useState, useRef , useEffect} from "react";
import {
  FileText,
  AlertCircle,
  CheckCircle,
  CircleHelp,
  RefreshCw,
  GitBranch,
  AlertTriangle,
  CircuitBoard,
  Languages,
  ScanLine,
  MessageCircle,
  ListFilter,
  Network,
  ShieldCheck,
  Scale,
  ChevronRight,
  Factory,  // Added Factory
} from "lucide-react";
import { PlayCircle } from 'lucide-react';
import Player from '@vimeo/player';
import { GUIDELINES } from "./utils/constants";
import { 
  POLICY_EXTRACTION_URL, 
  POLICY_EXTRACTION_PROMPTID_MOTOR,
  POLICY_EXTRACTION_PROMPTID_HOME,
  POLICY_EXTRACTION_PROMPTID_PET,
  POLICY_VALIDATION_URL,  // You'll need to add these to your apiManager
  POLICY_VALIDATION_PROMPTID, 
  GUIDED_FREE_TEXT_URL, 
  GUIDED_FREE_TEXT_PROMPTID,
  HEADER_CLIENT_KEY 
} from "./utils/apiManager";
import LineOfBusinessSelector from "./components/LineOfBusinessSelector";
import PolicyCard from "./components/PolicyCard";
import { POLICY_WORDINGS, POLICYHOLDER_SCHEDULE  } from './utils/constants';
import ExtractAnimation from "./components/ExtractAnimation";
import AnimatedBackground from "./components/AnimatedBackground";
import AnimatedBorder from "./components/AnimatedBorder";
import TryDemoButton from "./components/TryDemoButton";
import ThreeStepProgress from "./components/ThreeStepProgress";
import TypeWriter from "./components/TypeWriter";

import useAccessToken from "./useAccessToken";

const PolicyExtraction = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");
  const [isExtracting, setIsExtracting] = useState(false);
  const [policyInfo, setPolicyInfo] = useState(null);
  const demoRef = useRef(null);

  const getExtractionPromptId = (businessType) => {
    switch (businessType) {
      case "pet":
        return POLICY_EXTRACTION_PROMPTID_PET;
      case "home":
        return POLICY_EXTRACTION_PROMPTID_HOME;
      case "motor":
      default:
        return POLICY_EXTRACTION_PROMPTID_MOTOR;
    }
  };
  const benefitLabels = ["Describe your Incident", "See AI in Action", "Your Benefits"];
  const [selectedLineOfBusiness, setSelectedLineOfBusiness] = useState({
    id: "motor",
    label: "Motor",
    value: "Motor",
    icon: Factory,
    color: "#00D05E",
    size: 24,
    description: "Motor",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [validationResults, setValidationResults] = useState(null);

  const resetState = () => {
    setCurrentStep(1);
    setDescription("");
    setAiGuidance("");
    setIsExtracting(false);
    setPolicyInfo(null);
  };
  const accessToken = useAccessToken();
  const videoContainerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [aiGuidance, setAiGuidance] = useState("");
  const [isGuidanceLoading, setIsGuidanceLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handlePlayVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play().then(() => {
        setIsVideoPlaying(true);
      }).catch(error => {
        console.error("Error playing video:", error);
      });
    }
  };

  useEffect(() => {
    if (description) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
  
      const timeout = setTimeout(() => {
        fetchGuidance(description);
      }, 600);
  
      setTypingTimeout(timeout);
    }
  
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [description]);

  useEffect(() => {
    if (videoContainerRef.current) {
      const options = {
        url: 'https://vimeo.com/1029826008/a4bc340b60',
        autoplay: false,
        loop: true,
        controls: true,
        responsive: true,
        muted: false,
        title: false,
        byline: false,
        portrait: false
      };
  
      const player = new Player(videoContainerRef.current, options);
      playerInstanceRef.current = player;
  
      player.ready().then(() => {
        player.setVolume(1);
      }).catch(error => {
        console.error("Player ready error:", error);
      });
  
      return () => {
        if (playerInstanceRef.current) {
          playerInstanceRef.current.destroy();
        }
      };
    }
  }, []);

  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const extractPolicyInfo = async () => {
  //   setIsExtracting(true);
  //   try {
  //     const response = await fetch("http://localhost:8000/retrieve_policy", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
  //       },
  //       body: JSON.stringify({
  //         claim_data: {
  //           incident_type: "accident",
  //           incident_location: "Westminster",
  //           claim_narrative: description,
  //         },
  //       }),
  //     });
  //     const data = await response.json();
  //     setPolicyInfo(data.policy_information);

  //     setTimeout(() => {
  //       setIsExtracting(false);
  //       setCurrentStep(2);
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setIsExtracting(false);
  //   }
  // };
  const fetchGuidance = async (text) => {
    setIsGuidanceLoading(true);
    try {
      const guideline = selectedLineOfBusiness.id === 'motor' 
        ? GUIDELINES.GUIDED_FREE_TEXT.guideline_motor
        : selectedLineOfBusiness.id === 'home'
        ? GUIDELINES.GUIDED_FREE_TEXT.guideline_home
        : GUIDELINES.GUIDED_FREE_TEXT.guideline_pet;
  
      const requestAiServiceBody = {
        promptId: GUIDED_FREE_TEXT_PROMPTID,
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: `{'guideline': '${guideline}', 'freetext': '${text}'}`
          }
        ]
      };
  
      const response = await fetch(GUIDED_FREE_TEXT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          "x-client-key": HEADER_CLIENT_KEY,
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestAiServiceBody),
      });
  
      const data = await response.json();
      let module_response = data.result;
      module_response = module_response.replace("```json\n", "").replace("\n```", "");
      module_response = JSON.parse(module_response);
      
      setAiGuidance(module_response.Explanation || "");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsGuidanceLoading(false);
    }
  };

  const validatePolicy = async (text) => {
    setIsLoading(true);
    try {
      // First API call - Policy Extraction
      // const extractionBody = {
      //   insurance_policy: `${POLICY_WORDINGS.MOTOR.policy_text}`,
      //   claim_narrative: text
      // };
  
      const extractionRequestBody = {
        promptId: getExtractionPromptId(selectedLineOfBusiness.id),
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: description //JSON.stringify(extractionBody)
          }
        ]
      };
  
      // Get Policy Extraction data
      const extractionResponse = await fetch(POLICY_EXTRACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          "x-client-key": HEADER_CLIENT_KEY,
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(extractionRequestBody),
      }).then(res => res.json());
  
      let extraction_response = extractionResponse.result;
      extraction_response = extraction_response.replace("```json\n", "").replace("\n```", "");
      extraction_response = JSON.parse(extraction_response);
      console.log(extraction_response)
  
      // Parse the extraction response
      const policy_inclusions = extraction_response["Policy Information (for the specific Incident Type)"]["Policy Inclusions"];
      const policy_exclusions = extraction_response["Policy Information (for the specific Incident Type)"]["Policy Exclusions"];
      const general_exclusions = extraction_response["General and Other Exclusions based on the Claim Scenario"]["General Exclusions and Conditions"];
      const other_exclusions = extraction_response["General and Other Exclusions based on the Claim Scenario"]["Other Exclusions"];
      const claim_narrative = extraction_response["General and Other Exclusions based on the Claim Scenario"]["Claim Narrative"];
      const claim_narrative_meaning = extraction_response["General and Other Exclusions based on the Claim Scenario"]["Meaning"];
      
      let conditions_contents;
      try {
        conditions_contents = extraction_response["Conditions for Contents, Personal Possessions, Non-Specified Items, and Other Items (for eg, bicycles, pedal cycles etc.)"];
      } catch {
        conditions_contents = extraction_response["Conditions for Contents, Personal Possessions, Non-Specified Items, and Other Items"];
      }
      
      const schedule_validation_points = extraction_response["Schedule Validation"];
  
      // Create validator ingress
      const validator_ingress = {
        // policy_information: {
        //   "Policy Inclusions": policy_inclusions,
        //   "Policy Exclusions": policy_exclusions,
        //   "General Exclusions": general_exclusions,
        //   "Other Exclusions": other_exclusions,
        //   "Policyholder Schedule": POLICYHOLDER_SCHEDULE.MOTOR.schedule,
        //   "Conditions for Contents, Personal Possessions, Non-Specified Items and Other Items": conditions_contents,
        //   "Schedule Validation Pointers": schedule_validation_points,
        // },
        // claim_data: {
        //   "Claim Information": description,
        //   "Claim Narrative": claim_narrative,
        //   "Claim Narrative Meaning": claim_narrative_meaning
        // }
      };
      const policyInformation = `
      {
        "Policy Inclusions": "${policy_inclusions}",
        "Policy Exclusions": "${policy_exclusions}",
        "General Exclusions": "${general_exclusions}",
        "Other Exclusions": "${other_exclusions}",
        "Policyholder Schedule": "${POLICYHOLDER_SCHEDULE.MOTOR.schedule}",
        "Conditions for Contents, Personal Possessions, Non-Specified Items and Other Items": "${conditions_contents}",
        "Schedule Validation Pointers": "${schedule_validation_points}"
      }`;
      
      const claimData = `
      {
        "Claim Information": "${description}",
        "Claim Narrative": "${claim_narrative}",
        "Claim Narrative Meaning": "${claim_narrative_meaning}"
      }`;
      
      const validator_ingress_string = `
      {
        "policy_information": ${policyInformation},
        "claim_data": ${claimData}
      }`;

      // Second API call - Policy Validation
      // const validationRequestBody = {
      //   promptId: POLICY_VALIDATION_PROMPTID,
      //   dataKeys: [
      //     {
      //       Key: "UserNarrative",
      //       Value: JSON.stringify(validator_ingress)
      //     }
      //   ]
      // };
      const validationRequestBody = {
        promptId: POLICY_VALIDATION_PROMPTID,
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: validator_ingress_string
          }
        ]
      };
  
      const validationResponse = await fetch(POLICY_VALIDATION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          "x-client-key": HEADER_CLIENT_KEY,
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(validationRequestBody),
      }).then(res => res.json());
  
      let module_response = validationResponse.result;
      module_response = module_response.replace("```json\n", "").replace("\n```", "");
      module_response = JSON.parse(module_response);
      
    // Parse the validation response
    const parsedResponse = {
      Inclusions: module_response["Relating Claim to Policy Inclusions"][0]['Policy Inclusions'],
      Exclusions: module_response["Relating Claim to Policy Exclusions"][0]['Policy Exclusions'],
      Recommendation: module_response["Recommendation"],
      Explanation: module_response["Explanation"],
    };

    setValidationResults(parsedResponse);
    console.log("Parsed validation results:", parsedResponse);
    
    setCurrentStep(2);
  } catch (error) {
    console.error("Error:", error);
  } finally {
    setIsLoading(false);
  }
};

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="relative min-h-[300px]">
            {!isLoading && (
              <>
                <div className="space-y-6">
                  <div className="space-y-4">
                    <div className="flex items-center gap-2 mb-1">
                      <FileText className="text-[#00D05E]" size={24} />
                      <p className="text-white">Select Insurance Type</p>
                    </div>
                    <LineOfBusinessSelector
                      selected={selectedLineOfBusiness}
                      onSelect={setSelectedLineOfBusiness}
                    />
                  </div>
      
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <p className="text-white mb-3 flex justify-start items-center">
                        <CircleHelp size={24} color="#00D05E" />
                        <span className="ml-2">Describe your Incident</span>
                      </p>
                      <div className="w-full h-64 bg-black/20 rounded-3xl">
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={`Describe what happened with your ${selectedLineOfBusiness.label.toLowerCase()} incident...`}
                          className="w-full h-full text-white focus:outline-none resize-none placeholder-gray-500 bg-black/20 p-4 rounded-3xl"
                        />
                      </div>
                    </div>
      
                    <div>
                      <p className="text-white mb-3 flex justify-start items-center">
                        <MessageCircle size={24} color="#00D05E" />
                        <span className="ml-2">AI Guidance</span>
                      </p>
                      <div className="relative w-full h-64 bg-black/20 rounded-3xl p-4 overflow-y-auto custom-scrollbar">
                        <div className="absolute top-6 right-6 px-2 py-1 text-sm text-[#00D05E] flex items-center z-50">
                          <div className={`w-2 h-2 rounded-full mr-2 ${
                            isGuidanceLoading
                              ? "bg-yellow-500 animate-pulse"
                              : aiGuidance
                              ? "bg-[#00D05E]"
                              : "bg-[#00D05E]"
                          }`} />
                          {isGuidanceLoading
                            ? "Thinking..."
                            : aiGuidance
                            ? "Active"
                            : "Waiting for input"}
                        </div>
                        {aiGuidance ? (
                          <div className="flex items-start gap-3 animate-fade-in">
                            <div className="w-8 h-8 rounded-full bg-[#00D05E]/20 flex items-center justify-center border border-[#00D05E]/30">
                              <MessageCircle size={16} className="text-[#00D05E]" />
                            </div>
                            <div className="flex-1">
                              <div className="bg-[#00D05E]/30 text-white p-4 pt-9 rounded-3xl backdrop-blur-sm border border-[#00D05E]/30">
                                {aiGuidance}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="h-full flex items-center justify-center text-gray-400">
                            <p className="text-center">
                              Start typing your incident description, and I'll help
                              guide you through the process.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
      
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={() => validatePolicy(description)}
                      disabled={!description.trim() || isLoading}
                      className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50"
                    >
                      Validate Claim
                    </button>
                  </div>
                </div>
              </>
            )}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-[10px] border border-gray-800" />
                <ExtractAnimation />
              </div>
            )}
          </div>
        );

        case 2:
          return (
            <div className="space-y-8">
              <div className="grid grid-cols-1 gap-6">
                {validationResults && (
                  <>
                    <AnimatedBorder>
                      <div className="bg-black/95 p-6 rounded-3xl h-full">
                        <h3 className="text-white text-lg font-bold mb-4 flex items-center">
                          <CheckCircle className="text-[#00D05E] mr-2" size={24} />
                          Validation Results
                        </h3>
                        <div className="space-y-4">
                          <div className="bg-[#00D05E]/10 border border-[#00D05E] rounded-lg p-4">
                            <p className="text-[#00D05E] font-semibold">Recommendation</p>
                            <p className="text-white mt-2">{validationResults.Recommendation}</p>
                          </div>
                          <div className="bg-black/40 rounded-lg p-4 border border-gray-800">
                            <p className="text-white font-semibold">Explanation</p>
                            <p className="text-gray-400 mt-2">{validationResults.Explanation}</p>
                          </div>
                        </div>
                      </div>
                    </AnimatedBorder>
        
                    {/* Changed this div to be responsive */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <AnimatedBorder>
                        <div className="bg-black/95 p-6 rounded-3xl h-full">
                          <h3 className="text-white text-base font-bold mb-4">
                            Applicable Policy Inclusions
                          </h3>
                          <p className="text-[#00D05E] italic">
                            {validationResults.Inclusions}
                          </p>
                        </div>
                      </AnimatedBorder>
                      <AnimatedBorder>
                        <div className="bg-black/95 p-6 rounded-3xl h-full">
                          <h3 className="text-white text-base font-bold mb-4">
                            Potential Policy Exclusions
                          </h3>
                          <p className="text-red-500 italic">
                            {validationResults.Exclusions}
                          </p>
                        </div>
                      </AnimatedBorder>
                    </div>
                  </>
                )}
              </div>
        
              <div className="flex justify-end">
                <button
                  onClick={() => setCurrentStep(3)}
                  className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
                >
                  Continue
                </button>
              </div>
            </div>
          );

      case 3:
        return (
          <div className="space-y-6 text-white">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <FileText className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Smart Extraction" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Contextually identifies and extracts relevant policy sections"
                      delay={10}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <AlertCircle className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Quick Review" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Enables rapid assessment of policy coverage and exclusions"
                      delay={20}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <FileText className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Automated Analysis" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Reduces manual review time and improves accuracy"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
            </div>

            <div className="flex justify-end">
              <button
                onClick={resetState}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50 flex justify-center items-center"
              >
                <RefreshCw size={18} />
                <span className="ml-2">Try Again</span>
              </button>
            </div>
          </div>
        );
    }
  };

return (
  <div className="relative py-32">
    <AnimatedBackground />
    {/* Hero Section with Video and Info Boxes */}
    <div className="max-w-5xl mx-auto mb-9">
      {/* Title and Video Row */}
      <div className="flex flex-col md:flex-row items-start justify-between gap-8 mb-6">
        <div className="flex-1">
          <h1 className="text-[32px] md:text-[40px] font-['Impact']  text-[#00D05E] mb-4">
            Welcome to Policy Validation Module
          </h1>
          <p className="text-[#ACACAC] leading-relaxed mb-6">
            Let our AI assistant help validate your claims and assess coverage instantly.
          </p>
          
          {/* Info Boxes Below Title */}
          <div className="grid grid-cols-1 gap-6">
            <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
              <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                <GitBranch />
                <span className="ml-2">End-to-End Claims Validation</span>
              </h2>
              <p className="leading-[160%] font-light text-[#ACACAC]">
                The Policy Validation module combines multiple intelligent capabilities to deliver 
                comprehensive claims validation and coverage assessment. It streamlines the entire 
                validation process into one smooth journey, from initial description to final coverage 
                recommendation.
              </p>
            </section>
            
            <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
              <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                <AlertTriangle />
                <span className="ml-2">Current Challenges</span>
              </h2>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Incomplete claim information and details</span>
              </p>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Multiple back-and-forth communications</span>
              </p>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Delayed coverage assessments</span>
              </p>
            </section>
          </div>
        </div>
        
        {/* Video Avatar */}
        <div className="w-full md:w-[320px] shrink-0">
          <div className="aspect-[9/16] relative rounded-xl border border-gray-800 overflow-hidden bg-black/40">
            <div 
              ref={videoContainerRef} 
              className="w-full h-full z-10 relative pointer-events-auto"
              style={{
                position: 'relative',
                zIndex: 50
              }}
            />
            
            {/* Play Overlay */}
            {!isVideoPlaying && (
              <div 
                onClick={handlePlayVideo}
                className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 cursor-pointer z-[60] transition-opacity hover:bg-black/40"
              >
                <div className="w-16 h-16 rounded-full bg-[#00D05E]/20 flex items-center justify-center backdrop-blur-sm border border-[#00D05E]/30 mb-3">
                  <PlayCircle size={40} className="text-[#00D05E]" />
                </div>
                <p className="text-white text-center px-4">
                  Click to meet your AI assistant
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

  {/* Technical Capabilities Section */}
  <div className="grid grid-cols-1 gap-6 mb-9">
    <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
      <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
        <CircuitBoard />
        <span className="ml-2">Technical Capabilities</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <Languages />
            <span className="ml-2">Smart Guidance</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Intelligent prompts ensure complete information capture
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <ScanLine />
            <span className="ml-2">Auto-Categorisation</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Precise incident classification and categorisation
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <ListFilter />
            <span className="ml-2">Data Validation</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Cross-references details for consistency checks
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <Network />
            <span className="ml-2">Policy Analysis</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Real-time extraction and analysis of policy sections
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <ShieldCheck />
            <span className="ml-2">Coverage Assessment</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Immediate coverage recommendations
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <Scale />
            <span className="ml-2">End-to-End Validation</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Comprehensive validation from start to finish
          </p>
        </div>
      </div>
    </section>
  </div>
        <div className="hidden md:flex justify-center items-center mb-9">
          <TryDemoButton onClick={scrollToDemo} />
        </div>
      </div>
{/* Demo Section Header */}
<section ref={demoRef}>
  <div className="max-w-4xl mx-auto text-center mb-9 pt-9">
    <h2 className="text-2xl font-bold text-[#00D05E] mb-2">
      POLICY VALIDATION DEMO
    </h2>
    {currentStep === 1 && (
      <p className="leading-[160%] font-light text-[#ACACAC]">
        See how <span className="text-[#F93DCD]">Optium</span> provides end-to-end 
        validation of your claim. Select your insurance type and describe what happened - 
        our AI will guide you through the entire validation process.
      </p>
    )}
    {currentStep === 2 && (
      <p className="leading-[160%] font-light text-[#ACACAC]">
        Optium has validated your claim and provided recommendations
      </p>
    )}
    {currentStep === 3 && (
      <p className="leading-[160%] font-light text-[#ACACAC]">
        What this means for your claims process
      </p>
    )}
  </div>

  <div className="max-w-4xl mx-auto text-center mb-9">
  <ThreeStepProgress 
      currentStep={currentStep} 
      labels={benefitLabels}
    />
  </div>

  {/* Demo Content */}
  <div className="max-w-5xl mx-auto">
    <AnimatedBorder>
      <div className="bg-black backdrop-blur-sm rounded-3xl border border-gray-800 p-6 overflow-hidden">
        <AnimatedBackground />
        {renderStep()} {/* This replaces your hardcoded content */}
      </div>
    </AnimatedBorder>
  </div>
</section>
    </div>
  );
};

export default PolicyExtraction;