// WeatherCard.js
import React from "react";

// const WeatherCard = ({ scenario, isSelected, onSelect }) => (
//   <div
//     onClick={() => onSelect(scenario)}
//     className={`relative p-6 rounded-xl cursor-pointer transition-all duration-300 ${
//       isSelected
//         ? 'bg-[#00D05E]/20 border-2 border-[#00D05E] scale-105'
//         : 'bg-black/20 border border-gray-800 hover:border-[#00D05E]/50'
//     }`}
//   >
//     <div className="text-4xl mb-4">{scenario.icon}</div>
//     <h3 className="text-white text-lg font-semibold mb-2">{scenario.label}</h3>
//     <p className="text-gray-400 text-sm">{scenario.description}</p>

//     {/* Example text */}
//     <div className="mt-4 p-3 bg-black/30 rounded-lg">
//       <p className="text-xs text-gray-500">Example claim:</p>
//       <p className="text-sm text-gray-300 italic">{scenario.sampleClaim}</p>
//     </div>
//   </div>
// );

const WeatherCard = ({ scenario, isSelected, onSelect }) => (
  <div
    onClick={() => onSelect(scenario)}
    className={`relative p-4 rounded-lg cursor-pointer transition-all duration-300 ${
      isSelected
        ? "bg-[#00D05E]/20 border-2 border-[#00D05E] scale-105"
        : "bg-black/20 border border-gray-800 hover:border-[#00D05E]/50"
    }`}
  >
    <div className="flex items-center gap-3">
      <div className="mr-1">{scenario.icon}</div>
      <div className="flex-1">
        <h3 className="text-white font-semibold">{scenario.label}</h3>
        <p className="text-gray-400 text-xs">{scenario.description}</p>
      </div>
    </div>

    {/* Example text - now more compact */}
    <div className="mt-2 p-2 bg-black/30 rounded-lg">
      <p className="text-xs text-gray-400 italic">"{scenario.sampleClaim}"</p>
    </div>
  </div>
);

export default WeatherCard;
