// components/PolicyCard.js
import React from "react";
import {
  Check,
  X,
  AlertCircle,
  Info,
  CheckCircle,
  CircleX,
  CircleAlert,
} from "lucide-react";

const iconMap = {
  "Policy Inclusions": Check,
  "Policy Exclusions": X,
  "General Exclusions": AlertCircle,
  "Other Exclusions": Info,
};

const listIconMap = {
  "Policy Inclusions": CheckCircle,
  "Policy Exclusions": CircleX,
  "General Exclusions": CircleAlert,
  "Other Exclusions": CircleAlert,
};

const listIconColorMap = {
  "Policy Inclusions": "#00D05E",
  "Policy Exclusions": "#ef4444",
  "General Exclusions": "#f59e0b",
  "Other Exclusions": "#f59e0b",
};

const colorMap = {
  "Policy Inclusions": "from-[#00D05E]/20 to-pink-700/20 border-[#00D05E]/30",
  "Policy Exclusions": "from-pink-500/20 to-[#00D05E]/20 border-pink-500/30",
  "General Exclusions": "from-[#00D05E]/20 to-pink-700/20 border-pink-500/30",
  "Other Exclusions": "from-[#00D05E]/20 to-pink-700/20 border-pink-500/30",
};

const PolicyCard = ({ title, items }) => {
  const Icon = iconMap[title];
  const ListIcon = listIconMap[title];

  return (
    <div
      className={`relative group overflow-hidden rounded-3xl bg-gradient-to-br ${colorMap[title]} border backdrop-blur-sm transition-all duration-500 hover:scale-[101%] h-[450px]`}
    >
      <div className="p-6 h-full flex flex-col">
        <div className="flex items-center gap-3 mb-4">
          <div
            className={`w-10 h-10 rounded-full bg-gradient-to-br from-gray-800 to-black flex items-center justify-center`}
          >
            <Icon
              className={`w-5 h-5 ${
                title.includes("Inclusions")
                  ? "text-[#00D05E]"
                  : "text-pink-400"
              }`}
            />
          </div>
          <h3 className="text-lg font-bold text-[#00D05E]">{title}</h3>
        </div>

        <div className="space-y-2 flex-grow overflow-y-auto custom-scrollbar pr-2">
          {" "}
          {/* Added pr-2 for scroll spacing */}
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-start gap-2 text-sm text-gray-300 group/item"
            >
              <span className="mt-1 mr-2">
                <ListIcon size={16} color={`${listIconColorMap[title]}`} />
              </span>
              <span className="group-hover/item:text-white transition-colors duration-300 leading-[160%] text-base">
                {item}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PolicyCard;
