const ModuleIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={color} clipPath="url(#clip0_7309_348)">
        <path d="m5.375 5.855 3.375-1.94v2.368l.75-.453V3.49a1.06 1.06 0 0 0-.478-.856L5.492.505a1 1 0 0 0-.983-.001L.98 2.635a1.06 1.06 0 0 0-.479.856V7.76a1.06 1.06 0 0 0 .478.855l3.531 2.132a.99.99 0 0 0 .982 0L7.25 9.685v-.876L5.375 9.94zm-.478-4.71a.24.24 0 0 1 .206.001l3.395 2.05-3.476 1.997-3.519-1.999zM1.366 7.974a.33.33 0 0 1-.116-.213V3.913l3.375 1.918v4.11zm15.156 7.412-1-.603-.725.438 1.201.725-3.476 1.998-3.519-1.999 1.2-.724-.726-.438-.999.603A1.06 1.06 0 0 0 8 16.24v4.268a1.06 1.06 0 0 0 .478.856l3.531 2.132a1 1 0 0 0 .982 0l3.53-2.132a1.06 1.06 0 0 0 .479-.856v-4.268a1.06 1.06 0 0 0-.478-.856m-7.656 5.338a.33.33 0 0 1-.116-.214v-3.846l3.375 1.917v4.11zm7.268 0-3.259 1.967v-4.085l3.375-1.94v3.844a.33.33 0 0 1-.116.214m7.888-18.088-3.53-2.13a1 1 0 0 0-.983-.001l-3.53 2.131a1.06 1.06 0 0 0-.479.856V5.83l.75.453v-2.37l3.375 1.918v4.11L17.75 8.809v.876l1.76 1.062a1 1 0 0 0 .98 0l3.532-2.132a1.06 1.06 0 0 0 .478-.855V3.49a1.06 1.06 0 0 0-.478-.856m-4.125-1.49a.24.24 0 0 1 .206.001l3.395 2.05-3.476 1.997-3.519-1.999zm3.737 6.828-3.259 1.968V5.855l3.375-1.94V7.76a.33.33 0 0 1-.116.213"></path>
        <path d="m16.522 7.323-3.53-2.131a1 1 0 0 0-.983 0l-3.53 2.13A1.06 1.06 0 0 0 8 8.18v4.268a1.06 1.06 0 0 0 .478.856l3.531 2.131a1 1 0 0 0 .982 0l3.53-2.131a1.06 1.06 0 0 0 .479-.856V8.18a1.06 1.06 0 0 0-.478-.856m-4.125-1.49a.24.24 0 0 1 .206 0l3.395 2.05-3.476 1.998-3.519-1.999zm-3.531 6.828a.33.33 0 0 1-.116-.214V8.601l3.375 1.917v4.11zm7.268 0-3.259 1.967v-4.085l3.375-1.94v3.844a.33.33 0 0 1-.116.214"></path>
      </g>
      <defs>
        <clipPath id="clip0_7309_348">
          <path fill={color} d="M.5 0h24v24H.5z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ModuleIcon;
