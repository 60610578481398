const ThreeStepProgress = ({ currentStep, labels = ["Describe your Incident", "Your AI Output", "Module Capabilities"] }) => {
  const StepItems = [1, 2, 3].map((item, index) => (
    <div key={item} className="flex flex-col justify-center items-center">
      <div
        className={`w-8 h-8 rounded-full flex justify-center items-center mb-3 ${
          item < currentStep
            ? "bg-[#00D05E]/95 border border-[#00D05E]"
            : currentStep === item
            ? "bg-[#00D05E] border border-[#00D05E]"
            : "bg-[#583768]/95 border border-[#583768]"
        }`}
      >
        {item}
      </div>
      <div className="h-8 text-center text-sm">{labels[index]}</div>
    </div>
  ));
  return (
    <div className="relative">
      <div className="grid grid-cols-3 text-white">{StepItems}</div>
      <div className="w-[68%] absolute top-[22%] left-[16%] -z-10">
        <div className="h-[1px] bg-[#583768]"></div>
        <div
          className={`h-[1px] bg-[#00D05E] transition-[width] duration-500 ease-linear ${
            currentStep === 1 ? "w-0" : currentStep === 2 ? "w-1/2" : "w-full"
          }`}
        ></div>
      </div>
    </div>
  );
};

export default ThreeStepProgress;
