const RightArrowIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="m15.353 11.647-4.5-4.5a.5.5 0 0 0-.707.707L14.293 12l-4.147 4.147a.5.5 0 0 0 .707.707l4.5-4.5a.5.5 0 0 0 0-.707"
      ></path>
    </svg>
  );
};

export default RightArrowIcon;
