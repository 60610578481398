const BASE_URL = "https://ai-service-test.rightindem-internal.com/";  
// const BASE_URL = "http://localhost:8080/";
const CAUSE_SUB_CAUSE_SELECTION_URL = BASE_URL + "ExecutionHandler/Execute";  
const GUIDED_FREE_TEXT_URL = BASE_URL + "ExecutionHandler/Execute";  
const HIERARCHY_SELECTION_URL = BASE_URL + "ExecutionHandler/Execute";  
const INCONSISTENCY_IDENTIFICATION_URL = BASE_URL + "ExecutionHandler/Execute";  
const POLICY_EXTRACTION_URL = BASE_URL + "ExecutionHandler/Execute";  
const POLICY_VALIDATION_URL = BASE_URL + "ExecutionHandler/Execute";  
  
const HEADER_CLIENT_KEY = "00A530E2-C420-4BB5-A248-D9EA2692B388";  
  
// const HIERARCHY_SELECTION_PROMPTID = "2fbd0384-bb90-43b8-8428-eaaeb42bbfee";  
const HIERARCHY_SELECTION_PROMPTID = "79c6c7e5-081f-4894-9307-fb3a11807f8a";  
// const CAUSE_SUBCAUSE_PROMPTID_PET_INSURANCE = "15b92405-a90c-4f03-9ae2-440a67c90e3d";  
const CAUSE_SUBCAUSE_PROMPTID_PET_INSURANCE = "95f14a08-e74c-4e95-9c1b-71b532433ae6";  
// const CAUSE_SUBCAUSE_PROMPTID_HOME_INSURANCE = "c9200dd5-78eb-40d3-97fc-762ca94734a7";
const CAUSE_SUBCAUSE_PROMPTID_HOME_INSURANCE = "03a9d44c-8342-422e-8c14-7ed1b014d927";   
// const CAUSE_SUBCAUSE_PROMPTID_MOTOR_INSURANCE = "21940dd2-55a6-4525-8fc4-bcee556e8927";  
const CAUSE_SUBCAUSE_PROMPTID_MOTOR_INSURANCE = "f43d5afe-7edf-4cd3-bff5-fca46b7e67f4";
const INCONSISTENCY_IDENTIFICATION_PROMPTID = "c6fd07f6-7fdb-4def-853a-d3f41f93bf4c";  
const GUIDED_FREE_TEXT_PROMPTID = "4cd3732e-f381-40fd-9c61-129f9719cc14";  
// const POLICY_EXTRACTION_PROMPTID = "3a666fd5-f776-440d-a11f-8f29e51b972d";  
const POLICY_EXTRACTION_PROMPTID_MOTOR = "de8720fc-0259-4139-be8a-49422d773ea4";
const POLICY_EXTRACTION_PROMPTID_HOME = "957e587b-4c12-463b-abf2-225968d25534";
const POLICY_EXTRACTION_PROMPTID_PET = "16ee459a-4466-48a2-8efa-58b50d42fc6d";
const POLICY_VALIDATION_PROMPTID = "14c44aaa-f5e1-402e-81f4-fdb3184c38bd";  
  
export {  
  CAUSE_SUB_CAUSE_SELECTION_URL,  
  GUIDED_FREE_TEXT_URL,  
  HIERARCHY_SELECTION_URL,  
  INCONSISTENCY_IDENTIFICATION_URL,  
  POLICY_EXTRACTION_URL,  
  POLICY_VALIDATION_URL,  
  HEADER_CLIENT_KEY,  
  HIERARCHY_SELECTION_PROMPTID,  
  CAUSE_SUBCAUSE_PROMPTID_PET_INSURANCE,  
  CAUSE_SUBCAUSE_PROMPTID_HOME_INSURANCE,  
  CAUSE_SUBCAUSE_PROMPTID_MOTOR_INSURANCE,  
  INCONSISTENCY_IDENTIFICATION_PROMPTID,  
  GUIDED_FREE_TEXT_PROMPTID,  
  POLICY_EXTRACTION_PROMPTID_MOTOR,  
  POLICY_EXTRACTION_PROMPTID_HOME,
  POLICY_EXTRACTION_PROMPTID_PET,
  POLICY_VALIDATION_PROMPTID  
};  