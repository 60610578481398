import { Outlet } from "react-router-dom";
import TopNav from "./components/TopNav";
import MainNavigationDesktop from "./components/MainNavigationDesktop";
import MainNavigationMobile from "./components/MainNavigationMobile";
import { useState } from "react";

const MainLayout = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen((prevState) => !prevState);
  };

  return (
    <div className="relative py-[14px] px-4 h-screen">
      {/* Background with animated gradients */}
      <div className="fixed inset-0 bg-black -z-10">
        <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-pink-500/10 blur-[120px] animate-blob1" />
        <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-[#00D05E]/10 blur-[120px] animate-blob2" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/3 h-1/3 bg-purple-500/10 blur-[120px] animate-blob3" />
      </div>

      <div className="flex gap-6 h-full">
        {/* Floating Navigation */}
        <MainNavigationDesktop />
        <MainNavigationMobile
          isMobileNavOpen={isMobileNavOpen}
          toggleMobileNav={toggleMobileNav}
        />
        {/* Main Content Area */}
        <div className="flex-1 bg-white/0.5 rounded-xl relative">
          <TopNav toggleMobileNav={toggleMobileNav} />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
