const AnimatedBorder = ({ children, extraClasses = "" }) => {
  return (
    <div
      className={`bg-gradient-to-r from-[#00D05E] via-[#F93DCD] to-[#00D05E] animate-gradient bg-[length:200%_100%] p-[1px] rounded-3xl overflow-hidden ${extraClasses}`}
    >
      {children}
    </div>
  );
};

export default AnimatedBorder;
