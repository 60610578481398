import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Player from '@vimeo/player';
import AnimatedBackground from "./components/AnimatedBackground";

const HomePage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [videoEnded, setVideoEnded] = useState(false);
  const playerRef = useRef(null);

  // Handle mobile viewport height
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      const player = new Player(playerRef.current);

      player.ready().then(() => {
        player.setPlaybackRate(1.5);
        player.setCurrentTime(10);
      }).catch(console.error);
    }

    // Only trigger loginWithRedirect if user is not authenticated
    if (!isAuthenticated) {
      const timer = setTimeout(() => {
        setVideoEnded(true);
        loginWithRedirect({
          appState: { returnTo: location.state?.from?.pathname || "/dashboard" }
        });
      }, 13500);

      return () => clearTimeout(timer);
    }
  }, [loginWithRedirect, location.state, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const returnTo = location.state?.from?.pathname || "/dashboard";
      navigate(returnTo, { replace: true });
    }
  }, [isAuthenticated, navigate, location.state]);

  useEffect(() => {
    if (isAuthenticated) {
      const returnTo = location.state?.from?.pathname || "/dashboard";
      navigate(returnTo, { replace: true });
    }
  }, [isAuthenticated, navigate, location.state]);

  return (
    <div 
      className="fixed inset-0 w-full overflow-hidden"
      style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
    >
      {!videoEnded && (
        <>
          <div className="absolute inset-0 z-0">
            <iframe
              ref={playerRef}
              src="https://player.vimeo.com/video/1029244625?h=4a4f30a933&autoplay=1&background=1&app_id=122963"
              className="w-full h-full scale-[1.5]"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{ 
                border: 0,
                pointerEvents: 'none'
              }}
              title="AI Co-Pilot Overview"
            />
          </div>
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent z-10" />
        </>
      )}
      <AnimatedBackground />
    </div>
  );
};

export default HomePage;

// import { useAuth0 } from "@auth0/auth0-react";
// import AnimatedBackground from "./components/AnimatedBackground";
// import { Navigate } from "react-router-dom";

// const HomePage = () => {
//   const { loginWithRedirect, isAuthenticated } = useAuth0();

//   if (isAuthenticated) {
//     return <Navigate to="/dashboard" />;
//   }

//   return (
//     <div className="flex flex-col justify-center items-center w-screen h-screen bg-black/55">
//       <AnimatedBackground />
//       {/* <img src="/logo.jpeg" alt="Optium" className="w-64 h-auto mb-4" /> */}
//       <button
//         className="font-semibold bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
//         onClick={loginWithRedirect}
//       >
//         Try Optium AI
//       </button>
//     </div>
//   );
// };

// export default HomePage;
