import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "./MainLayout";
import GuidedFreeText from "./GuidedFreeText";
import HierarchySelection from "./HierarchySelection";
import InconsistencyIdentification from "./InconsistencyIdentification";
import PolicyExtraction from "./PolicyExtraction";
import Layout from './components/Layout';
import Dashboard from "./Dashboard";
import PolicyValidation from "./PolicyValidation";
import CauseSubCauseSelection from "./CauseSubCauseSelection";
import HomePage from "./HomePage";
import ProtectedRoute from "./components/ProtectedRoute";
import { useAuth0 } from "@auth0/auth0-react";

// require('newrelic');

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return null; // Or a loading spinner
  }

  return (
    <Router>
      <Layout>
        <Routes>
          {/* Redirect authenticated users from home to dashboard */}
          <Route 
            path="/" 
            element={
              isAuthenticated ? 
                <Navigate to="/dashboard" replace /> : 
                <HomePage />
            } 
          />
          
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="category-selection" element={<HierarchySelection />} />
            <Route path="guided-text" element={<GuidedFreeText />} />
            <Route
              path="inconsistency"
              element={<InconsistencyIdentification />}
            />
            <Route path="policy-extraction" element={<PolicyExtraction />} />
            <Route path="policy-validation" element={<PolicyValidation />} />
            <Route
              path="cause-sub-cause-selection"
              element={<CauseSubCauseSelection />}
            />
          </Route>

          {/* Catch all route for unknown paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
