//src/CauseSubCauseSelection.js - Current
import React, { useState, useRef, useEffect} from "react";
import AnimatedBackground from "./components/AnimatedBackground";
import LoadingSequence from "./components/LoadingSequence";
import { PlayCircle } from 'lucide-react';
import Player from '@vimeo/player';
import {ChevronRight, CheckCircle, CircleHelp, FileText, RefreshCw } from "lucide-react";
import AnimatedBorder from "./components/AnimatedBorder";
import TryDemoButton from "./components/TryDemoButton";
import { Dog, Factory, House, AlertCircle } from "lucide-react";
import ThreeStepProgress from "./components/ThreeStepProgress";
import TypeWriter from "./components/TypeWriter";
import {LayersIcon, BrainCircuit } from "lucide-react";
import LineOfBusinessSelector from "./components/LineOfBusinessSelector";
import {
  CAUSE_SUB_CAUSE_SELECTION_URL,
  CAUSE_SUBCAUSE_PROMPTID_PET_INSURANCE,
  CAUSE_SUBCAUSE_PROMPTID_HOME_INSURANCE,
  CAUSE_SUBCAUSE_PROMPTID_MOTOR_INSURANCE,
  HEADER_CLIENT_KEY,
} from "./utils/apiManager";
import useAccessToken from "./useAccessToken";
import {
  GitBranch,
  AlertTriangle,
  CircuitBoard, // Changed from Circuit
  Languages,
  ScanLine,
  ListFilter,
  Network, // Changed from Workflow
  ShieldCheck,
  Scale,
} from "lucide-react";

const CauseSubCauseSelection = () => {
  const demoRef = useRef(null);
  // Add new state management
  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [aiOutput, setAiOutput] = useState(null);
  const benefitLabels = ["Describe your Incident", "See AI in Action", "Your Benefits"];
  
  const [selectedLineOfBusiness, setSelectedLineOfBusiness] = useState({
    id: "motor",
    label: "Motor",
    value: "Motor",
    icon: Factory,
    color: "#00D05E",
    size: 24,
    description: "Motor",
  });

  // useEffect(() => {
  //   if (!selectedLineOfBusiness) {
  //     setSelectedLineOfBusiness("motor");
  //   }
  // }, []);

  const getPromptId = (businessType) => {
    switch (businessType) {
      case "pet":
        return CAUSE_SUBCAUSE_PROMPTID_PET_INSURANCE;
      case "home":
        return CAUSE_SUBCAUSE_PROMPTID_HOME_INSURANCE;
      case "motor":
      default:
        return CAUSE_SUBCAUSE_PROMPTID_MOTOR_INSURANCE;
    }
  };

  const resetState = () => {
    setCurrentStep(1);
    setDescription("");
    setSelectedLineOfBusiness({
      id: "motor",
      label: "Motor",
      value: "Motor",
      icon: Factory,
      color: "#00D05E",
      size: 24,
      description: "Motor",
    }); // Reset to default motor object instead of null
    setIsLoading(false);
    setAiOutput(null);
  };
  const accessToken = useAccessToken();

  const videoContainerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const handlePlayVideo = () => {
    if (playerInstanceRef.current) {
      playerInstanceRef.current.play().then(() => {
        setIsVideoPlaying(true);
      }).catch(error => {
        console.error("Error playing video:", error);
      });
    }
  };
  
  useEffect(() => {
    if (videoContainerRef.current) {
      const options = {
        url: 'https://vimeo.com/1029824628/24b90841eb',
        autoplay: false,
        loop: true,
        controls: true,
        responsive: true,
        muted: false,
        title: false,
        byline: false,
        portrait: false
      };
  
      const player = new Player(videoContainerRef.current, options);
      playerInstanceRef.current = player;
  
      player.ready().then(() => {
        player.setVolume(1);
      }).catch(error => {
        console.error("Player ready error:", error);
      });
  
      return () => {
        if (playerInstanceRef.current) {
          playerInstanceRef.current.destroy();
        }
      };
    }
  }, []);
  const scrollToDemo = () => {
    demoRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const analyzeDescription = async (text) => {
  //   setIsLoading(true);
  //   try {
  //     // Start API call
  //     const apiPromise = fetch(CAUSE_SUB_CAUSE_SELECTION_URL, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
  //       },
  //       body: JSON.stringify({
  //         claim_data: {
  //           claim_narrative: text,
  //         },
  //       }),
  //     }).then((res) => res.json());

  //     // Wait for both the API and the animation sequence
  //     const [data] = await Promise.all([
  //       apiPromise,
  //       new Promise((resolve) => setTimeout(resolve, 12000)), // Wait for animation sequence
  //     ]);

  //     const processedOutput = data.map((item) => {
  //       const parts = item.split(" -> ");
  //       return {
  //         incidentType: parts.length > 2 ? parts[0] : "Accident",
  //         cause: parts.length > 2 ? parts[1] : parts[0],
  //         subCase: parts[parts.length - 1],
  //         liability: "Fault",
  //       };
  //     });

  //     setAiOutput(processedOutput);
  //     setCurrentStep(2);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  
  const analyzeDescription = async (text) => {
    setIsLoading(true);
    try {
      const requestAiServiceBody = {
        promptId: getPromptId(selectedLineOfBusiness.id),
        dataKeys: [
          {
            Key: "UserNarrative",
            Value: text
          }
        ]
      };
  
      const apiPromise = fetch(CAUSE_SUB_CAUSE_SELECTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "validation-key": "KaEQfEOP0ZMXbkgElkuPRv6iUrMxQDytwrQPKhH0eO8",
          "x-client-key": HEADER_CLIENT_KEY,
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestAiServiceBody),
      }).then((res) => res.json());
  
      const [data] = await Promise.all([
        apiPromise,
        new Promise((resolve) => setTimeout(resolve, 12000)),
      ]);
  
      let module_response = data.result;
      module_response = module_response.replace("```json\n", "").replace("\n```", "");
      module_response = JSON.parse(module_response);
      
      console.log(module_response);
      
      // Check if Possible options is empty
      if (!module_response["Possible options"] || module_response["Possible options"].length === 0) {
        setAiOutput([{
          incidentType: "Invalid",
          cause: "Invalid Description",
          subCase: "The incident description is not valid. Please enter a valid description",
          liability: "Invalid"
        }]);
        setCurrentStep(2);
        return;
      }

      let assessments = module_response["assessment %"];
      let max_assessment = Math.max(...Object.values(assessments));
      let threshold = 10;
      
      let best_option_keys = Object.keys(assessments).filter(option => 
        max_assessment - assessments[option] <= threshold
      );
      
      let best_options = [];
      module_response["Possible options"].forEach(option_dict => {
        best_option_keys.forEach(key => {
          if (key in option_dict) {
            best_options.push(option_dict[key]);
          }
        });
      });
      
      let bestOptions = [];
      best_options.forEach(option => {
        bestOptions.push(option);
      });
  
      const processedOutput = bestOptions.map((item) => {
        const parts = item.split(" -> ");
        return {
          incidentType: parts.length > 2 ? parts[0] : parts[0],
          cause: parts.length > 2 ? parts[1] : parts[0],
          subCase: parts[parts.length - 1],
          liability: "Fault/Non-Fault/Partial",
        };
      });
  
      setAiOutput(processedOutput);
      setCurrentStep(2);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add the renderStep function
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="relative min-h-[300px]">
            {!isLoading && (
              <div className="space-y-6">
                <div className="space-y-4">
                  <div className="flex items-center gap-2 mb-1">
                    <FileText className="text-[#00D05E]" size={24} />
                    <p className="text-white">Select Insurance Type</p>
                  </div>
                  <LineOfBusinessSelector
                    selected={selectedLineOfBusiness}
                    onSelect={setSelectedLineOfBusiness}
                  />
                </div>
      
                <div className="space-y-4">
                  <div className="flex items-center gap-2 mb-1">
                    <CircleHelp className="text-[#00D05E]" size={24} />
                    <p className="text-white">Describe your Incident</p>
                  </div>
                  <div className="bg-black/40 backdrop-blur-sm rounded-[10px] border border-gray-800 overflow-hidden">
                    <div className="space-y-4 p-3">
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={`Describe what happened with your ${selectedLineOfBusiness.label.toLowerCase()} incident...`}
                        className="w-full h-32 bg-transparent rounded-lg text-white focus:outline-none resize-none placeholder-gray-500 p-4"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end">
                        <button
                          onClick={() => analyzeDescription(description)}
                          disabled={!description.trim() || isLoading}
                          className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50"
                        >
                          Continue
                        </button>
                      </div>
                </div>
              </div>
            )}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-[10px] border border-gray-800" />
                <LoadingSequence />
              </div>
            )}
          </div>
        );
  
        case 2:
  // Check for invalid description
  if (aiOutput.length === 1 && aiOutput[0].subCase === "The incident description is not valid. Please enter a valid description") {
    return (
      <div className="space-y-8">
        <AnimatedBorder>
          <div className="bg-black/95 p-4 md:p-6 rounded-3xl">
            <div className="flex items-start gap-4">
              <div className="bg-[#F93DCD]/10 p-1.5 md:p-2 rounded-full">
                <AlertCircle className="text-[#F93DCD] w-5 h-5 md:w-6 md:h-6" />
              </div>
              <div>
                <h3 className="text-white text-base md:text-lg font-semibold mb-2">
                  Invalid Description
                </h3>
                <p className="text-[#ACACAC] text-sm md:text-base leading-relaxed">
                  The incident description is not valid. Please enter a valid description.
                </p>
              </div>
            </div>
          </div>
        </AnimatedBorder>
        <div className="flex justify-end mt-6">
          <button
            onClick={() => setCurrentStep(1)}  // Note: Changed to go back to step 1
            className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }
          // First check if there's an unlisted incident
          else if (aiOutput.some(output => 
            output.cause === "Unlisted Incident" || 
            output.subCase === "Unlisted Incident"
          )) {
            return (
              <div className="space-y-8">
                <AnimatedBorder>
                  <div className="bg-black/95 p-4 md:p-6 rounded-3xl">
                    <div className="flex items-start gap-4">
                      <div className="bg-[#F93DCD]/10 p-1.5 md:p-2 rounded-full">
                        <AlertCircle className="text-[#F93DCD] w-5 h-5 md:w-6 md:h-6" />
                      </div>
                      <div>
                        <h3 className="text-white text-base md:text-lg font-semibold mb-2">
                          Category Not Found
                        </h3>
                        <p className="text-[#ACACAC] text-sm md:text-base leading-relaxed">
                          For this demo we have selected {' '}
                          {selectedLineOfBusiness.id === 'motor' ? '90 motor' : 
                           selectedLineOfBusiness.id === 'home' ? '75 home' : '60 pet'} incident 
                          categories. Please refer to our list{' '}
                          <a href="#" className="text-[#00D05E] hover:text-[#00D05E]/80 underline">
                            here
                          </a>.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimatedBorder>
                <div className="flex justify-end mt-6">
                  <button
                    onClick={() => setCurrentStep(3)}
                    className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
                  >
                    Continue
                  </button>
                </div>
              </div>
            );
          }
        
          // If no unlisted incident, show the regular grid
          return (
            <div className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                {/* Incident Type Card */}
                <AnimatedBorder>
                  <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
                    <div className="flex items-start justify-between mb-4">
                      <h3 className="text-[#ACACAC] text-sm md:text-base">
                        Incident Type
                      </h3>
                      <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
                        <AlertCircle className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
                      </div>
                    </div>
                    <div className="space-y-2">
                      {[...new Set(aiOutput.map(output => output.incidentType))].map((type, index) => (
                        <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
                          <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
                            {type}
                          </p>
                          {index > 0 && (
                            <span className="text-[#ACACAC] text-xs">Alternative Match</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </AnimatedBorder>
        
                {/* Cause Card */}
                <AnimatedBorder>
                  <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
                    <div className="flex items-start justify-between mb-4">
                      <h3 className="text-[#ACACAC] text-sm md:text-base">
                        Cause
                      </h3>
                      <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
                        <GitBranch className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
                      </div>
                    </div>
                    <div className="space-y-2">
                      {[...new Set(aiOutput.map(output => output.cause))].map((cause, index) => (
                        <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
                          <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
                            {cause}
                          </p>
                          {index > 0 && (
                            <span className="text-[#ACACAC] text-xs">Alternative Match</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </AnimatedBorder>
        
                {/* Sub-Case Card */}
                <AnimatedBorder>
                  <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
                    <div className="flex items-start justify-between mb-4">
                      <h3 className="text-[#ACACAC] text-sm md:text-base">
                        Sub-Case
                      </h3>
                      <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
                        <ListFilter className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
                      </div>
                    </div>
                    <div className="space-y-2">
                      {[...new Set(aiOutput.map(output => output.subCase))].map((subCase, index) => (
                        <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
                          <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
                            {subCase}
                          </p>
                          {index > 0 && (
                            <span className="text-[#ACACAC] text-xs">Alternative Match</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </AnimatedBorder>
        
                {/* Liability Card */}
                <AnimatedBorder>
                  <div className="bg-black/95 p-4 md:p-6 rounded-3xl h-full">
                    <div className="flex items-start justify-between mb-4">
                      <h3 className="text-[#ACACAC] text-sm md:text-base">
                        Liability
                      </h3>
                      <div className="bg-[#00D05E]/10 p-1.5 md:p-2 rounded-full">
                        <Scale className="text-[#00D05E] w-4 h-4 md:w-5 md:h-5" />
                      </div>
                    </div>
                    <div className="space-y-2">
                      {[...new Set(aiOutput.map(output => output.liability))].map((liability, index) => (
                        <div key={index} className={`${index > 0 ? 'pt-2 border-t border-gray-800' : ''}`}>
                          <p className="text-[#00D05E] text-base md:text-lg font-semibold break-words">
                            {liability}
                          </p>
                          {index > 0 && (
                            <span className="text-[#ACACAC] text-xs">Alternative Match</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </AnimatedBorder>
              </div>
        
              <div className="flex justify-end mt-6">
                <button
                  onClick={() => setCurrentStep(3)}
                  className="w-full md:w-auto bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors"
                >
                  Continue
                </button>
              </div>
            </div>
          );
  
      case 3:
        return (
          <div className="space-y-6 text-white">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <FileText className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Multi-Insurance Support" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Seamlessly handles categorisation across motor, home, and pet insurance claims"
                      delay={10}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <LayersIcon className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Natural Language Processing" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="Understands and processes claims descriptions in everyday language"
                      delay={20}
                    />
                  </p>
                </div>
              </AnimatedBorder>
              <AnimatedBorder extraClasses="transform hover:scale-[102%] transition-all duration-300">
                <div className="h-full bg-black/90 p-6 rounded-3xl">
                  <BrainCircuit className="w-8 h-8 text-[#00D05E] mb-4" />
                  <h4 className="text-xl font-semibold text-[#00D05E] mb-2">
                    <TypeWriter text="Accurate Classification" />
                  </h4>
                  <p className="text-[#ACACAC]">
                    <TypeWriter
                      text="98% accuracy in identifying the correct cause and sub-cause categories"
                      delay={30}
                    />
                  </p>
                </div>
              </AnimatedBorder>
            </div>
  
            <div className="flex justify-end">
              <button
                onClick={resetState}
                className="bg-[#00D05E] text-white px-4 py-2 rounded-lg hover:bg-[#00D05E]/75 transition-colors disabled:opacity-50 flex justify-center items-center"
              >
                <RefreshCw size={18} />
                <span className="ml-2">Try Again</span>
              </button>
            </div>
          </div>
        );
  
      default:
        return null;
    }
  };

return (
  <div className="relative py-32">
    <AnimatedBackground />
    {/* Hero Section with Video and Info Boxes */}
    <div className="max-w-5xl mx-auto mb-9">
      {/* Title and Video Row */}
      <div className="flex flex-col md:flex-row items-start justify-between gap-8 mb-6">
        <div className="flex-1">
          <h1 className="text-[32px] md:text-[40px] font-['Impact']  text-[#00D05E] mb-4">
            Welcome to Cause and Sub-Cause Selection Module
          </h1>
          <p className="text-[#ACACAC] leading-relaxed mb-6">
            Let our AI assistant help simplify the claims categorisation process.
          </p>
          
          {/* Info Boxes Below Title */}
          <div className="grid grid-cols-1 gap-6">
            <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
              <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                <GitBranch />
                <span className="ml-2">Your AI Claims Assistant</span>
              </h2>
              <p className="leading-[160%] font-light text-[#ACACAC]">
                Making a claim after an accident can be stressful. Our AI Assistant guides 
                you through the process just like a human claims handler would, helping you 
                describe what happened in your own words. No more confusing questions or 
                complex category selections - we handle all of that for you automatically.
              </p>
            </section>
            
            <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50">
              <h2 className="text-xl font-semibold text-[#F93DCD] mb-2 flex items-center">
                <AlertTriangle />
                <span className="ml-2">Common Frustrations We Solve</span>
              </h2>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Confusing questions during claims submission</span>
              </p>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Having to choose from endless lists of categories</span>
              </p>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Multiple callbacks requesting more information</span>
              </p>
              <p className="leading-[160%] font-light text-[#ACACAC] flex items-center mb-2">
                <ChevronRight size={14} />
                <span className="ml-2">Delayed claim settlements</span>
              </p>
            </section>
          </div>
        </div>
        
        {/* Video Avatar */}
        <div className="w-full md:w-[320px] shrink-0">
          <div className="aspect-[9/16] relative rounded-xl border border-gray-800 overflow-hidden bg-black/40">
            <div 
              ref={videoContainerRef} 
              className="w-full h-full z-10 relative pointer-events-auto"
              style={{
                position: 'relative',
                zIndex: 50
              }}
            />
            
            {/* Play Overlay */}
            {!isVideoPlaying && (
              <div 
                onClick={handlePlayVideo}
                className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 cursor-pointer z-[60] transition-opacity hover:bg-black/40"
              >
                <div className="w-16 h-16 rounded-full bg-[#00D05E]/20 flex items-center justify-center backdrop-blur-sm border border-[#00D05E]/30 mb-3">
                  <PlayCircle size={40} className="text-[#00D05E]" />
                </div>
                <p className="text-white text-center px-4">
                  Click to meet your AI assistant
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
  {/* Technical Capabilities Section */}
  <div className="grid grid-cols-1 gap-6 mb-9">
    <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
      <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
        <CircuitBoard />
        <span className="ml-2">How We Help</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <Languages />
            <span className="ml-2">Speak Naturally</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Describe what happened in your own words - no technical jargon needed
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <ScanLine />
            <span className="ml-2">All Insurance Types</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Works for motor, home, and pet insurance claims
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <ListFilter />
            <span className="ml-2">98% Accuracy</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Gets your claim details right the first time
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <Network />
            <span className="ml-2">Instant Results</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            No waiting - get immediate categorisation of your claim
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <ShieldCheck />
            <span className="ml-2">Fewer Mistakes</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Reduces errors and the need for follow-up calls
          </p>
        </div>
        <div className="bg-black/20 backdrop-blur-sm rounded-xl p-6 border border-gray-800/50 h-full">
          <p className="leading-[160%] font-semibold text-[#00D05E] flex items-center mb-2">
            <Scale />
            <span className="ml-2">Faster Settlement</span>
          </p>
          <p className="leading-[160%] text-sm font-light text-[#ACACAC]">
            Get your claim settled quicker with accurate categorisation
          </p>
        </div>
      </div>
    </section>
  </div>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-9">
  <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
    <h2 className="text-xl font-semibold text-[#00D05E] mb-2 flex items-center">
      <Scale />
      <span className="ml-2">Our Performance</span>
    </h2>
    <div className="space-y-4">
      <div className="flex items-center justify-between bg-black/40 rounded-lg p-4">
        <span className="text-[#ACACAC]">Customer Accuracy</span>
        <span className="text-[#F93DCD] font-bold">51%</span>
      </div>
      <div className="flex items-center justify-between bg-black/40 rounded-lg p-4">
        <span className="text-[#ACACAC]">AI Assistant Accuracy</span>
        <span className="text-[#00D05E] font-bold">95%</span>
      </div>
      <div className="flex items-center justify-between bg-black/40 rounded-lg p-4">
        <span className="text-[#ACACAC]">'Other' Category Selection</span>
        <span className="text-[#F93DCD] font-bold">20%</span>
      </div>
      <div className="flex items-center justify-between bg-black/40 rounded-lg p-4">
        <span className="text-[#ACACAC]">AI 'Other' Correction Rate</span>
        <span className="text-[#00D05E] font-bold">98%</span>
      </div>
      {/* <div className="flex items-center justify-between bg-black/40 rounded-lg p-4">
        <span className="text-[#ACACAC]">Claims With Missing Data</span>
        <span className="text-[#00D05E] font-bold">80%</span>
      </div>
      <div className="flex items-center justify-between bg-black/40 rounded-lg p-4">
        <span className="text-[#ACACAC]">Potential Time Saved</span>
        <span className="text-[#00D05E] font-bold">150 Days</span>
      </div> */}
    </div>
  </section>

  <section className="bg-black/20 backdrop-blur-sm rounded-3xl p-6 border border-gray-800/50 h-full">
    <h2 className="text-xl font-semibold text-[#00D05E] mb-2">What This Means</h2>
    <div className="space-y-3">
      <p className="leading-[160%] font-light text-[#ACACAC]">
        Over 20% of customers select 'Other' despite having relevant categories available. 
        Traditional systems only achieve 51% accuracy in category selection.
      </p>
      <p className="leading-[160%] font-light text-[#ACACAC]">
        Our AI Assistant achieves 95% accuracy across all categories and can correctly 
        categorise 98% of claims where customers selected 'Other'.
      </p>
      <p className="leading-[160%] font-light text-[#ACACAC]">
        This means faster processing, fewer follow-ups, and quicker settlements for your claims.
      </p>
    </div>
  </section>
</div>
        <div className="hidden md:flex justify-center items-center mb-9">
          <TryDemoButton onClick={scrollToDemo} />
        </div>
      </div>
  
      {/* Demo Section */}
      <section ref={demoRef}>
  <div className="max-w-4xl mx-auto text-center mb-9 pt-9">
    <h2 className="text-2xl font-bold text-[#00D05E] mb-2">
      TRY IT YOURSELF
    </h2>
    {currentStep === 1 && (
      <p className="leading-[160%] font-light text-[#ACACAC]">
        Experience how <span className="text-[#F93DCD]">Optium</span> makes claims categorisation 
        simple. Just select your insurance type and describe what happened in your own words - 
        our AI Assistant will handle everything else. <span className="text-xl text-[#F93DCD]">By the way, you can have as many categories as you want for any line of business.</span>
      </p>
    )}
    {currentStep === 2 && (
      <p className="leading-[160%] font-light text-[#ACACAC]">
        {aiOutput.length > 1
          ? "Here are the most relevant categories we found based on your description"
          : "We've identified the perfect category match for your claim"}
      </p>
    )}
    {currentStep === 3 && (
      <p className="leading-[160%] font-light text-[#ACACAC]">
        See how this helps streamline your claims journey
      </p>
    )}
  </div>

  <div className="max-w-4xl mx-auto text-center mb-9">
    <ThreeStepProgress 
      currentStep={currentStep} 
      labels={benefitLabels}
    />
  </div>
  
        <div className="max-w-5xl mx-auto">
          <AnimatedBorder>
            <div className="bg-black backdrop-blur-sm rounded-3xl border border-gray-800 p-6 overflow-hidden">
              <AnimatedBackground />
              {renderStep()}
            </div>
          </AnimatedBorder>
        </div>
      </section>
    </div>
  );
};

export default CauseSubCauseSelection;
