// AnalyzingAnimation.js
import React from "react";
import { Search } from "lucide-react";

const AnalyzingAnimation = () => (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="bg-black/80 rounded-xl p-8 flex flex-col items-center gap-4">
      <div className="relative">
        <Search size={48} className="text-[#00D05E] animate-ping" />
        <Search size={48} className="text-[#00D05E] absolute inset-0" />
      </div>
      <p className="text-white text-xl">Analyzing Inconsistencies</p>
      <div className="flex gap-2">
        <span
          className="w-2 h-2 rounded-full bg-[#00D05E] animate-bounce"
          style={{ animationDelay: "0ms" }}
        />
        <span
          className="w-2 h-2 rounded-full bg-[#00D05E] animate-bounce"
          style={{ animationDelay: "150ms" }}
        />
        <span
          className="w-2 h-2 rounded-full bg-[#00D05E] animate-bounce"
          style={{ animationDelay: "300ms" }}
        />
      </div>
    </div>
  </div>
);

export default AnalyzingAnimation;
