import React from "react";

const AnimatedBackground = () => {
  return (
    <div className="fixed inset-0 bg-black/90 -z-10">
      <div className="relative w-full h-full overflow-hidden">
        <div className="animate-blob1 absolute top-0 right-1/4 w-96 h-96 bg-pink-500/30 rounded-full blur-[128px]" />
        <div className="animate-blob2 absolute bottom-0 left-1/4 w-96 h-96 bg-[#00D05E]/30 rounded-full blur-[128px]" />
        <div className="animate-blob3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-purple-500/30 rounded-full blur-[128px]" />
      </div>
    </div>
  );
};

export default AnimatedBackground;
