const UseCaseIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={color} clipPath="url(#clip0_7309_400)">
        <path d="M8.05 10.23a1.9 1.9 0 0 1-.693-.367l-3.45 3.45h1.06zm4.075.074h.75v6.946h-.75zm7.907 3.008h1.06l-3.449-3.45c-.202.171-.438.296-.692.369zM8.562 9.555h7.876a1.126 1.126 0 0 0 1.125-1.125V1.875A1.126 1.126 0 0 0 16.438.75H12.9a1.12 1.12 0 0 0-.934.499l-.791 1.18H8.562a1.126 1.126 0 0 0-1.124 1.125V8.43a1.126 1.126 0 0 0 1.125 1.125m7.876-.75H8.563a.375.375 0 0 1-.376-.375V4.687H9.85a.37.37 0 0 1 .311.167.4.4 0 0 0 .044.054l.77.784a.75.75 0 0 0 .6.3h5.238v2.437a.375.375 0 0 1-.375.375M8.563 3.18h2.612a.75.75 0 0 0 .623-.332l.79-1.18A.37.37 0 0 1 12.9 1.5h3.537a.376.376 0 0 1 .375.375v3.367h-5.237a.4.4 0 0 0-.044-.054l-.769-.783a1.12 1.12 0 0 0-.912-.468H8.188v-.383a.375.375 0 0 1 .374-.375M1.25 19.313H6.5a.75.75 0 0 0 .75-.75v-3.75a.75.75 0 0 0-.75-.75H1.25a.75.75 0 0 0-.75.75v3.75a.75.75 0 0 0 .75.75m0-4.5H6.5v3.75H1.25zm16.5 0v3.75a.75.75 0 0 0 .75.75h5.25a.75.75 0 0 0 .75-.75v-3.75a.75.75 0 0 0-.75-.75H18.5a.75.75 0 0 0-.75.75m6 3.75H18.5v-3.75h5.25zm-14.625.187v3.75a.75.75 0 0 0 .75.75h5.25a.75.75 0 0 0 .75-.75v-3.75a.75.75 0 0 0-.75-.75h-5.25a.75.75 0 0 0-.75.75m6 3.75h-5.25v-3.75h5.25z"></path>
        <path d="M11 20.25h3a.375.375 0 0 0 0-.75h-3a.375.375 0 0 0 0 .75m0 1.484h3a.375.375 0 0 0 0-.75h-3a.375.375 0 1 0 0 .75m-5.625-6.172h-3a.375.375 0 1 0 0 .75h3a.375.375 0 1 0 0-.75m0 1.485h-3a.375.375 0 1 0 0 .75h3a.375.375 0 1 0 0-.75m14.25-.735h3a.375.375 0 0 0 0-.75h-3a.375.375 0 0 0 0 .75m0 1.485h3a.375.375 0 0 0 0-.75h-3a.375.375 0 1 0 0 .75"></path>
      </g>
      <defs>
        <clipPath id="clip0_7309_400">
          <path fill={color} d="M.5 0h24v24H.5z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default UseCaseIcon;
